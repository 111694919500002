import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar2 from './design_sidebar2';
 
 class DigitalPr extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title>Get A Digital PR Service To Increase Your Online Visibility</title>
	<meta name="description" content="A digital PR service provides real value to your target audience by creating compelling pieces of content that increase awareness of your brand and online presence." />
	<link rel="canonical" href="https://bicads.com/digital-pr" />
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Digital PR' subtitle='Digital PR' BkImg='/assets/images/bicads/Banner/PR.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> Digital PR</h3>

            <h2 className='px-5 '>Build awareness for your brand</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" my-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceSecond '>
                        <h1 className='mainTextH4'>Digital PR Services In Pune and Mumbai (India)</h1>

                        <p className=' mainTextP'>A digital PR service is a fantastic tool that truly enriches the experiences of your desired audience. It does this by crafting engaging narratives that amplify the visibility of your brand and its virtual footprint. This service is a delightful blend of imaginative content marketing, SEO, and PR.
This exciting service is not just about churning out content; it's about producing captivating stories that resonate with your potential audience, thus enhancing your brand recognition and online reach. This is achieved through a well-balanced fusion of creative content marketing, SEO, and public relations tactics. The overall aim? To ignite your online presence and get your brand buzzing in the digital sphere!
This is a thrilling way to engage with your audience while also boosting your brand's online visibility. It's a unique blend of innovative content marketing strategies, search engine optimization techniques, and public relations efforts. All aimed at creating an impressive online presence for your brand. So why wait? Dive into the exciting world of digital PR services today!
</p>  
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/PR.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>How It Works?</h3>

            <h2 className='px-5 '>Boost brand awareness</h2>

            <p>Giving your company a boost in the search results while also providing engaging content that people love to share on social media is what we'll do.</p>
 

                </Container>
            </div>
          {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                <Row >
                    
                    <Col lg="9">
                     <Row >
                       
                      

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>PR Service Your Brand Needs
 </h4>

                        <p className=' mainTextP'>We're absolutely thrilled to let you know that our dedicated marketing squad is all set to craft and magnify an invaluable piece of content that will genuinely benefit your target group. This is our way of ensuring that your brand secures a competitive edge in the marketplace. Our strategies encompass SEO, PR, Inbound Marketing, and Content Marketing, all meticulously tailored to help you accomplish this objective.</p>  
                        </div>
                                     </Col>
                    

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>What your target market wants

 </h4>

                        <p className=' mainTextP'>If you're seeking to boost your visibility without necessarily interacting with more individuals, what alternatives do you have? The answer lies in Digital PR, which enables you to send the appropriate messages to the right target audience, using just the perfect content. This approach offers a great way to enhance your presence, all while maintaining your comfort zone. It's an exciting opportunity to reach out and make a meaningful impact!
</p>  
                        </div>
                                     </Col>
                     

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Connect with influencers
 </h4>

                        <p className=' mainTextP'>To truly amplify your digital footprint, partnering with an ideal PR firm is essential. Digital PR Service possesses expertise and utilities that can help you to form relationships with key influencers and take your online visibility to the next level.</p>  
                        </div>
                                     </Col>
                    
                        

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Improve your website rankings
 </h4>

                        <p className=' mainTextP'>We're in the business of crafting compelling narratives that will put your brand in the limelight, right on the platforms where your target demographic loves to hang out. We've got a solid partnership with search giants like Google and Bing, ensuring that your brand gets noticed in those critical search engine results. Not just that, we're all about strategic keyword usage, to help your brand climb up those search engine rankings!

</p>  
                        </div>
                        </Col>
                                     </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar2/>
                            </Col>   
                            </Row>
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}


             <Footer/>
        </div>
    );
} 
}
export default DigitalPr;