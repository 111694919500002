import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar from './design_sidebar';
 
 class MobileApp extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title>Mobile App Development - Stay Ahead Of The Competition</title>
	<meta name="description" content="Need a mobile app for your business? Look no further than the best mobile app development company. Our experts will help you get ahead of the competition with a custom mobile app." />
	<link rel="canonical" href="https://bicads.com/mobile-app-development-company" />
	
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Mobile App' subtitle='Development' BkImg='/assets/images/bicads/Banner/Mobile-App-development.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> Mobile App Development </h3>

            <h2 className='px-5 '>We’ll create web or mobile applications specifically for your company.
</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" my-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceSecond'>
                        <h1 className='mainTextH4'>Mobile Application Development Company In Pune and Mumbai (India)


 </h1>

                        <p className=' mainTextP'>Get ready to sky-rocket your business with our top-notch Mobile Application Development Company, ensuring you not only stay in the game but lead the pack!</p>  

                        <p className=' mainTextP'>In the current competitive business environment, launching a mobile application for your enterprise can be instrumental in maintaining existing clients and acquiring new ones. The development of a mobile app is a superb strategy for businesses to remain at par with their competitors. Mobile applications empower companies to stay relevant and competitive.</p>
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/Mobile-App-development.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>  Mobile app development</h3>

            <h2 className='px-5 '>Mobile app development is a powerful weapon in the world of business
</h2>

            <p>Mobile app development and deployment make it easier for businesses to take a step further in their competition by making it easier for the consumer to make payments. Mobile app development is cheaper and more convenient as a result, as well as a great marketing tool for businesses to use to highlight their brand.</p>

 
            <Row className='goodWebsite'>
                <Col lg='3'>  
                 <Image src="/assets/images/bicads/Icon-1.png" alt={config.SITE_NAME} fluid   />
                 <h5>Optimum Loading Speed</h5>
                 </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-2.png" alt={config.SITE_NAME} fluid   />
                    <h5>SEO Friendly Design</h5>

                    
                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-3.png" alt={config.SITE_NAME} fluid   />
                    <h5>Fully Mobile Responsive</h5>

                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-4.png" alt={config.SITE_NAME} fluid   />
                    <h5>Right Content Placement</h5>
                    </Col>
            </Row>
                </Container>
            </div>
         {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
         
        <div className='ServiceBg ServiceBg2'>
                <Container className="    ">

                <Row >
                    
                    <Col lg="9">
                     <Row >
                   
                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>How To Drive Your Business Ahead

 </h4>

                        <p className=' mainTextP'>Are you in need of a mobile application development or seeking someone to manage, maintain, and optimize it for you? We possess the requisite skills and resources to ensure your consumers receive an impressive app! Get in touch with us immediately and we will collaborate with you to create a polished product that your users will absolutely love!
</p>  
                        </div>
                                     </Col>
                         
                     
                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Mobile app makes all the difference

 </h4>

                        <p className=' mainTextP'>A mobile application serves as an efficient and budget-friendly promotional strategy. They offer a more advantageous way to accentuate your brand, surpassing the efficacy and cost-effectiveness of conventional marketing techniques. Through a mobile app, streamlining customer transactions becomes feasible, and it provides an avenue to enhance your conversion rate through enticing deals.
</p>  
                        </div>
                                     </Col>
                   

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Reach more of your customers
 </h4>

                        <p className=' mainTextP'>In today's competitive economic landscape, creating a mobile application is key to achieving success. Given the necessary financial backing, a skilled group, and a defined schedule, we are committed to designing and implementing your application. This will enable you to process transactions conveniently from anywhere.</p>  
                        </div>
                                     </Col>
                                     </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar/>
                            </Col>   
                            </Row>
  
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}


             <Footer/>
        </div>
    );
} 
}
export default MobileApp;