import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar2 from './design_sidebar2';
 
 class ContentMarketing extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title>The Best Content Marketing Services | Content Marketing Company</title>
	<meta name="description" content="Looking for the best content marketing services? Look no further than the Content Marketing Company. We provide high-quality, engaging content that will help promote your product in a passive manner." />
	<link rel="canonical" href="https://bicads.com/content-marketing-services" />
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Content Marketing' subtitle='Content Marketing' BkImg='/assets/images/bicads/Banner/Content-Marketing.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> Content Marketing</h3>

            <h2 className='px-5 '>Right Content For Better Business Communication</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" my-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceSecond pt-3'>
                        <h1 className='mainTextH4'>Content Marketing Services In Pune and Mumbai (India)


</h1>

                        <p className=' mainTextP'>Our Content Marketing Services are here to supercharge your brand's online presence, and we couldn't be more excited to help you dominate the digital landscape with quality content that resonates with your audience!</p> 

                          <p className=' mainTextP'>Content marketing encompasses the production of digital content, such as images, visual data presentations, audio broadcasts, motion pictures, and online articles, with the aim of subtly endorsing a product. Aggressive marketing approaches, like those seen on Facebook, can sometimes push potential customers away, which can negatively impact your business and brand identity. A firm that specializes in content marketing can be instrumental in providing valuable content to audiences while subtly associating it with your brand. Over time, content marketing has grown to be one of the most favored promotional strategies and is employed by top-tier corporations and websites globally.</p>  
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/Content-Marketing.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>How It Works?</h3>

            <h2 className='px-5 '>Developing Content To Grow Your Business



</h2>

            <p>We begin by determining what your audience should be able to do after reading your content. We then produce blog posts, infographics, podcasts, videos, and other marketing materials that help you achieve your goals.</p>
 
                </Container>
            </div>
            {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                <Row >
                    
                    <Col lg="9">
                     <Row >
                       
                      

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Increase your leads
 </h4>

                        <p className=' mainTextP'>Content marketing means creating valuable digital content like infographics, podcasts, videos, and blogs, rather than aggressively promoting your goods or services. This approach involves providing something of value to potential customers, without coming off as overly assertive or forceful. Content marketing firms aid businesses in providing this valuable content to their audience while maintaining a non-intrusive stance. This marketing paradigm has gained considerable popularity among various high-profile entities and internet platforms.</p>  
                        </div>
                                     </Col>
                 
                     

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Convert more leads

 </h4>

                        <p className=' mainTextP'>Boost the quantity of leads and inquiries by crafting exceptional content. A judiciously designed editorial calendar will lead to a surge in visitors on your website, boost conversions, and heighten the demand for your products. Having a robust strategy will amplify your voice, establishing you as a trusted information hub.

</p>  
                        </div>
                                     </Col>
                   

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Become a trusted brand
 </h4>

                        <p className=' mainTextP'>Producing top-notch content that values its readers' time is the secret to continuous client victory. If you're looking to expand your enterprise, now's the moment to put your money into content marketing. Services that focus on content marketing assist companies in crafting exceptional content that not only captivates their audience but also propels the growth of their business.</p>  
                        </div>
                                     </Col>
                     
                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Think beyond advertising
 </h4>

                        <p className=' mainTextP'>With the proper use of content marketing services, you can surge ahead of your rivals and give a robust boost to your SEO strategies. When you tactically curate online content, you can bolster your standing in search engine results, thus reinforcing your SEO endeavors. You have the potential to churn out top-notch content, thanks to the aid of content marketing services.

</p>  
                        </div>
                                     </Col>
                                     </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar2/>
                            </Col>   
                            </Row>
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}

             <Footer/>
        </div>
    );
} 
}
export default ContentMarketing;