import React, { Component } from 'react';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import { NavLink } from 'react-router-dom';
import {Fade} from 'react-awesome-reveal'

class OurServices extends Component {

    render(){
        return(
            <div className=" ourServices animateBtn "  style={{ 
                backgroundImage: `url(/assets/images/bicads-images/our-service/BG.jpg)` 
              }}>
                <Container >
                     <Row >
                        <Col lg={{ span:10, offset:1 }} className='pt-5  text-center'>
                        <h2 className='mainTextH1'>  BEST DIGITAL MARKETING AGENCY  </h2>
                        <h4 className='pt-2'>To Meet Your Marketing Goals </h4>
<p>Let's meet your audience right where they are! Cultivate trust and foster loyalty to trigger fresh sales. The role of digital platforms is highly significant in this context, and thanks to constant advancements in technology, individuals from a myriad of sectors have the ability to link up through numerous digital channels.</p>

<p>
A well thought-out digital marketing plan will pave the way for you to engage with your desired audience. Our seasoned team of professionals is at your service to assist you in creating and sustaining your presence online. We're thrilled at the prospect of helping you navigate the world of digital marketing with ease and efficiency!
</p>

                                          </Col>
                       <Col lg="12" className='serviceIcons'>

                        <Row className='pt-5 '>
                            <Col lg='4'>
                            <Fade direction='up'  delay="40" triggerOnce="true">
                                <div className='serviceBox'> 
                              <Image src="/assets/images/bicads-images/our-service/Design-&-Development-Icon.png" alt={config.SITE_NAME} className='wobble' fluid  rounded/>
                                
                                <h4>  Design & Development</h4>
                                <p>With our thoughtful designs for CRM, websites and mobile applications, we help your business with centrally managed database, build & maintain online presence and keep the customers engaged. 
</p>
</div>
</Fade>                 </Col>
                        
                            <Col lg='4'>
                            <Fade direction='up'  delay="80" triggerOnce="true">

                            <div className='serviceBox'> 
   
                                                <Image src="/assets/images/bicads-images/our-service/Digital-Marketing-Icon.png" alt={config.SITE_NAME} fluid  rounded/>
                                 <h4>Digital Marketing</h4>
                                <p>We offer wide range of services like SEO, optimizing your social media and performance marketing (PPC) on all platforms that will boost your business for online sales.</p>
                                </div>
                                </Fade>
                            </Col>
                         
                            <Col lg='4'>  
                            <Fade direction='up'  delay="120" triggerOnce="true">
   
                            <div className='serviceBox'> 
                     <Image src="/assets/images/bicads-images/our-service/Wikipedia-Icon.png" alt={config.SITE_NAME} fluid  rounded/>
 
                            <h4>Wikipedia & PR</h4>
                                <p>Wikipedia & digital PR will help your business build reputation. Reputation management is essential for any company as it helps maintain brand equity, keep customers happy, and retain team trust.</p>
                                </div>
                                </Fade>
                            </Col>
                        </Row>

  
                                              </Col>

                                              <Col className='text-center py-5'>
                                                                    
<NavLink  to="/our-services"   activeclassname="active"><button type="button" class="btn btn-primary btn-md  draw ">View All Services</button></NavLink></Col>
                    </Row>
                </Container>
            </div>
        );
    }

}

export default OurServices;