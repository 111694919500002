import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar2 from './design_sidebar2';
 
 class SearchEngine extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title>SEO Company - Search Engine Optimization Services</title>
	<meta name="description" content="SEO Company offers the best search engine optimization (SEO) services to help you increase your website's ranking and organic traffic" />
	<link rel="canonical" href="https://bicads.com/search-engine-optimization-company" />
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Search Engine Optimization' subtitle='Search Engine Optimization' BkImg='/assets/images/bicads/Banner/SEO.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> Search Engine Optimization</h3>

            <h2 className='px-5 '>Driving more traffic to your website by increasing rank using search engine optimization
</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" my-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceSecond pt-4'>
                        <h1 className='mainTextH4'>SEO – Search Engine Optimization Company In Pune and Mumbai (India)
</h1>

                        <p className=' mainTextP'>Who wouldn't be thrilled to know that with the right blend of search engine optimization techniques, you can enjoy a consistent stream of qualified leads without breaking the bank?
</p>  

<p className=' mainTextP'>A significant 57% of marketers in the B2B industry believe that SEO strategies generate more leads than any other marketing tactic. With a close rate of 14.6%, SEO leads outperform outbound leads, which only have a meagre 1.7% close rate. Once you've developed a web page that offers high value, there's no need to pay search engines to secure a top spot in search results. This is entirely different from the Pay Per Click ads strategy, where every click leading to your website incurs a cost.</p>  
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/SEO.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h1 className='mainTextH1'>How It Works?</h1>

            <h2 className='px-5 '>How SEO Will Benefit You

</h2>

            <p>SEO is a method for elevating a website's ranking on search engine results pages without paying for advertisement clicks. Those who invest in SEO typically outrank the competition and gain market share. SEO can help you attract anyone who has interest in a purchase at any point in the purchasing funnel, no matter what stage of the consumer's purchasing funnel they are in.
</p>

                </Container>
            </div>
          {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                            
                <Row >
                    
                    <Col lg="9">
                     <Row >
                       
                      

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Your organic results will speak
 </h4>

                        <p className=' mainTextP'>Search engine formulas highly value relevance and quality in deciding a website's position in search outcomes. This is why enhancing your site for search engines is advantageous—it allows you to reduce advertising expenses while still attracting visitors.</p>  
                        </div>
                                     </Col>
                     
                     
                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Stay Ahead Of The Competition

 </h4>

                        <p className=' mainTextP'>To stay competitive in the digital space, it's crucial to devise a plan for enhancing your website. There's no need to delay this - why not kick things off immediately? Your high-traffic pages' search rankings aren't influenced by pay-per-click ads that incur costs whenever someone clicks and lands on your page. Contrarily, these ads distinguish themselves as they levy a fee each time your page is viewed.
</p>  
                        </div>
                                     </Col>
                    
                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Gain brand awareness
 </h4>

                        <p className=' mainTextP'>Boosting the visibility of your incredible product or service can significantly enhance your potential to generate revenue as a company. By leveraging Search Engine Optimization Services, you're able to elevate your online brand recognition and attract high-quality leads. This approach is all about maximizing knowledge about your offerings, which consequently increases your financial success prospects. It's a thrilling way to make your mark in the digital world!</p>  
                        </div>
                                     </Col>
                                     </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar2/>
                            </Col>   
                            </Row>
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}



             <Footer/>
        </div>
    );
} 
}
export default SearchEngine;