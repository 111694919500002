import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

       
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar from './design_sidebar';
 
 class Ecommerce extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title>Ecommerce Website Development - How To Set Up An E-Commerce Website</title>
	<meta name="description" content="Setting up an ecommerce website is considerably less expensive than setting up an offline business. All of the sales procedures for your business are automated online. Ecommerce websites allow you to do business anywhere, without being constrained" />
	<link rel="canonical" href="https://bicads.com/e-commerce-website-development" />
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Website ' subtitle='Design' BkImg='/assets/images/bicads/Banner/Ecommerce.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> E-commerce Website Development</h3>

            <h2 className='px-5 '>Got A Passion?  <br></br> Take It Online With Us!!</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" py-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceSecond '>
                        <h1 className='mainTextH4'>E-commerce Website Development Services In Pune & Mumbai (India)
 </h1>

                        <p className='mainTextP'>We're thrilled to announce that with our top-notch e-commerce website development services, you too can achieve the heights of online retail success just like Amazon, maximizing your reach and sales like never before!</p>  

                        <p className='mainTextP'>Establishing an online retail platform is significantly more cost-effective than launching a brick-and-mortar store. All the sales operations within your business are efficiently automated online. Digital commerce sites offer the flexibility to conduct business without being tied down by physical locations. Amazon has elevated its online shopping platform to a successful level by consistently adjusting to consumer requirements and meticulously overseeing their product offerings. Consequently, they've expanded their reach, now offering an extensive array of items across various markets.</p>
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/Ecommerce.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>  Services 24/7 </h3>

            <h2 className='px-5 '>Sell Your Products & Services 24/7</h2>
            <p>Setting up an e-commerce website is a fantastic method to sell your items and services 24 hours a day, seven days a week. You may have up to 10 items, no up-front expenses or contracts, if you wish to begin an online business. An ecommerce website may be developed using the proper platform. You may sell your items and services to customers worldwide using an automated sales system, saving time and money.</p>

 
            <Row className='goodWebsite'>
                <Col lg='3'>  
                 <Image src="/assets/images/bicads/Icon-1.png" alt={config.SITE_NAME} fluid   />
                 <h5>Optimum Loading Speed</h5>
                 </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-2.png" alt={config.SITE_NAME} fluid   />
                    <h5>SEO Friendly Design</h5>

                    
                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-3.png" alt={config.SITE_NAME} fluid   />
                    <h5>Fully Mobile Responsive</h5>

                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-4.png" alt={config.SITE_NAME} fluid   />
                    <h5>Right Content Placement</h5>
                    </Col>
            </Row>
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                <Row >
                    
                    <Col lg="9">
                     <Row >
                       
                    
                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Build your business for less
 </h4>

                        <p className=' mainTextP'>Establishing an online retail platform is more cost-effective compared to constructing a physical establishment. This implies that you can expand your enterprise at a lower cost without having to confine your clientele to specific business hours. Online retail platforms do away with the geographical limitations typically encountered with office-oriented operations, allowing prospective customers the freedom to browse your site whenever convenient for them.</p>  
                        </div>
                                     </Col>
                    

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Work from anywhere

 </h4>

                        <p className=' mainTextP'>One of the best aspects of an online retail platform is its constant state of flux. You don't have to fret over technical aspects or managing a huge team of developers, as updates can be implemented instantly. All that's required is for you to input the necessary data and let the website creators handle the construction of the site.
</p>  
                        </div>
                                     </Col>
                   

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>You can measure success
 </h4>

                        <p className=' mainTextP'>An ecommerce website delivers comprehensive analytics, tracking everything from user interaction to conversion rates and the number of sessions. It offers a straightforward avenue to obtain immediate feedback on how your business is performing. Contemporary web development enables you not only to create leads but also to track the profit produced from these leads. Make informed choices by taking advantage of thorough analytics, which chart everything from user engagement to successful conversions and session numbers.</p>  
                        </div>
                                     </Col>
                    </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar/>
                            </Col>   
                            </Row>
  
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}


             <Footer/>
        </div>
    );
} 
}
export default Ecommerce;