import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar2 from './design_sidebar2';
 
 class SocialMedia extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title>SMM | Social Media Marketing Company | Get More Exposure For Your Business</title>
	<meta name="description" content="Need help getting your business seen online? Our social media marketing company can help. We offer a variety of services to get your business more exposure online. Contact us today to learn more!" />
	<link rel="canonical" href="https://bicads.com/social-media-marketing-company" />
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Social Media Marketing' subtitle='Social Media Marketing' BkImg='/assets/images/bicads/Banner/Social-Media-Marketing.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> Social Media Marketing</h3>

            <h2 className='px-5 '>Your Business Should Be Available Where Your Audience Are</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" my-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceFirst'>
                        <h1 className='mainTextH4'>SMM – Social Media Marketing Company In Pune and Mumbai (India) </h1>

                        <p className=' mainTextP'>Get ready to supercharge your brand's visibility and engagement like never before with the power of social media marketing - it's a game-changer!</p>  
                        <p  className=' mainTextP'>In today's digital age, social media platforms have become an integral component of every marketing scheme. The immense potential of social media marketing agencies as a budget-friendly tool is such that any business not leveraging it is missing out on a phenomenal promotional possibility.</p>
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/Social-Media-Marketing.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>How It Works?</h3>

            <h2 className='px-5 '>How Social Media Marketing Can Help You

</h2>

            <p>Social media marketing is loaded with advantages, so you want to use it. With just a few hours each week, you can boost your brand awareness and attract more traffic. Using a social media marketing company can help you get started.</p>

 
            <Row className='goodWebsite d-none'>
                <Col lg='3'>  
                 <Image src="/assets/images/bicads/Icon-1.png" alt={config.SITE_NAME} fluid   />
                 <h5>Optimum Loading Speed</h5>
                 </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-2.png" alt={config.SITE_NAME} fluid   />
                    <h5>SEO Friendly Design</h5>

                    
                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-3.png" alt={config.SITE_NAME} fluid   />
                    <h5>Fully Mobile Responsive</h5>

                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-4.png" alt={config.SITE_NAME} fluid   />
                    <h5>Right Content Placement</h5>
                    </Col>
            </Row>
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                     
                <Row >
                    
                    <Col lg="9">
                     <Row >
                 

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Increase ROI with existing traffic
 </h4>

                        <p className=' mainTextP'>Undeniably, social media plays a vital role in any promotional strategy, with its advantages being irrefutable. However, you're undoubtedly skipping out on a fantastic advertising chance if you neglect to incorporate it. Customers say that social media offered exceptional visibility for their business. Each shared post is exposed to a group of individuals, potentially leading to prospective customers.</p>  
                        </div>
                                     </Col>
                     
                       
                      
                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Improve on brand loyalty and customer satisfaction

 </h4>

                        <p className=' mainTextP'>Are you on the hunt for a social media marketing agency that can navigate the unpredictable landscape of social media? Our squad of seasoned professionals boasts years of in-depth expertise in this field. With our top-tier social media marketing offerings, we can assist you in cultivating customer allegiance and contentment.
</p>  
                        </div>
                                     </Col>
                     

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Why hire our services?
 </h4>

                        <p className=' mainTextP'>We enthusiastically approach marketing endeavors with a strategic, data-informed method that allows us to plan, implement, and gauge their effectiveness against our set goals. To ensure our campaigns are always improving and maintaining the highest quality, we employ the DMAIC process. This approach fosters accountability and keeps us on track for continuous enhancement in all our marketing actions.</p>  
                        </div>
                                     </Col>
                                     </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar2/>
                            </Col>   
                            </Row>
  
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}


             <Footer/>
        </div>
    );
} 
}
export default SocialMedia;