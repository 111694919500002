import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar from './design_sidebar';
 
 class GraphicDesign extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title>A Professional Graphic Design Company | Logo, Website, & Print Design</title>
	<meta name="description" content="We are a professional graphic design company that offers logo design, website design, and print design services. We have the right experience and work with clients all over the world." />
	<link rel="canonical" href="https://bicads.com/graphic-design-services" />
	
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Graphic Design' subtitle='Design' BkImg='/assets/images/bicads/Banner/Graphic-Designing.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> Graphic Design</h3>

            <h2 className='px-5 '>Customized Designs For Better Communication</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" my-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceFirst'>
                        <h1 className='mainTextH4'>Graphic Design Company In Pune and Mumbai (India)

 </h1>

                        <p className=' mainTextP'>Get ready to be blown away because our graphic design company is here to revolutionize your brand's visual identity with a fantastic mix of icons, shapes, hues, words, and images across all your materials!

</p>  

<p className=' mainTextP'>The art of graphic design involves organizing a combination of visual and textual elements to convey a specific message or concept. These elements can encompass icons, shapes, hues, words, and images. The application of graphic design can be seen across multiple mediums of printed materials such as posters, pamphlets, web interfaces, and beyond.

</p>  
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/Graphic-Designing.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>Design Matters</h3>

            <h2 className='px-5 '>Good Graphic Design For A Business
</h2>

            <p>A professional graphic designer can produce effective marketing materials like brochures, business cards, leaflets, banners, and mobile apps in addition to designing a logo. It combines photography, illustration, motion graphics to create attractive images which can capture the audience's attention, convert them into sales, and open up new channels of commerce.</p>

 
            <Row className='goodWebsite d-none'>
                <Col lg='3'>  
                 <Image src="/assets/images/bicads/Icon-1.png" alt={config.SITE_NAME} fluid   />
                 <h5>Optimum Loading Speed</h5>
                 </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-2.png" alt={config.SITE_NAME} fluid   />
                    <h5>SEO Friendly Design</h5>

                    
                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-3.png" alt={config.SITE_NAME} fluid   />
                    <h5>Fully Mobile Responsive</h5>

                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-4.png" alt={config.SITE_NAME} fluid   />
                    <h5>Right Content Placement</h5>
                    </Col>
            </Row>
                </Container>
            </div>
          {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                <Row >
                    
                    <Col lg="9">
                     <Row >
                       
                      

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Make a lasting impression
 </h4>

                        <p className=' mainTextP'>Producing a favourable initial impression on your clientele is the objective of proficient graphic design. We possess the capability to create logos and promotional content that are unambiguous and in line with your company's branding statement. By highlighting the crucial elements of your brand, we can attract your prospective audience.</p>  
                        </div>
                  </Col>
                       
                        
                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Right design can do wonders

 </h4>

                        <p className=' mainTextP'>Our squad of artistic creators are experts in the realm of branding, creating illustrations, and blueprinting layout designs. They have the proficiency to generate high-quality promotional materials that cater to businesses of all scales. Be it a petite startup or a mammoth multinational corporation, our team has the skill set to craft materials apt for every business type.

</p>  
                        </div>
                                     </Col>
                     

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Boost your marketing
 </h4>

                        <p className=' mainTextP'>Graphic design plays a crucial role in the business world. It serves as a linchpin for generating marketing resources that not only draw in potential clients but also turn them into loyal customers. Enhance your business performance by partnering with a graphic design firm.</p>  
                        </div>
                                     </Col>
                    

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Increase Sales & Market Position
 </h4>

                        <p className=' mainTextP'>When it comes to delivering the perfect message to your target audience, there's absolutely no space for misunderstanding. What you need is a proficient graphic designer who grasps your objectives and aids you in expressing them straightforwardly and unambiguously. Availing our Graphic Design Services allows you to articulate your thoughts in a clear and effective manner. This ensures that your messages resonate with your consumers, ultimately converting them into profitable sales.

</p>  
                        </div>
                                     </Col>
                                     </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar/>
                            </Col>   
                            </Row>
  
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}


             <Footer/>
        </div>
    );
} 
}
export default GraphicDesign;