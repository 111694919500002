import React, { Component ,useEffect ,useState}  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
      import AboutUs from '../components/section/about_us';
 import HomeTop from '../components/section/hometop';
import OurServices from '../components/section/our_services';
import WhyHire from '../components/section/whyHire';
import WhyConsistant from '../components/section/why_consistent';
import Portfolio from '../components/section/portfolio';
import BlogDiv from '../components/section/blog';

 import { Helmet, HelmetProvider } from "react-helmet-async";
import Owlslider from '../components/section/owlslider';
import { Modal, Button } from 'react-bootstrap';

     const Home = () => {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }
 // State to control the modal visibility
//  const [showModal, setShowModal] = useState(true);

//  // Function to close the modal
//  const handleClose = () => setShowModal(false);
//   // useEffect to open the modal on page load
//   useEffect(() => {
//     // Show the modal on mount
//     setShowModal(true);
//   }, []); // Empty dependency array ensures this effect runs only once on mount

    return(
        <div>
 
             <HelmetProvider>
             <meta charSet="utf-8" />
                <title> Digital Marketing Services &amp; Website Development Company | Pune | Mumbai </title>
	<meta name="description" content="Digital Marketing Services to build & maintain online presence. Website Development, Social Media, SEO, PR, Wikipedia Services" />
	<link rel="canonical" href="https://bicads.com/" />   
   </HelmetProvider>
   
        <Header  />  
        <HomeTop/> 
        <OurServices/>
        <Owlslider/>
         {/* <AboutUs/> */}
        <WhyHire/>
        {/* <WhyConsistant/> */}
        {/* <Portfolio/> */}
        <BlogDiv/>
        {/* <BookingForm/> */}
        {/* <AboutHome  Name="Abhi" Surname="Jagtap" />     */}
        {/* <Goal isGoal = {true}/> */}
        {/* <Owlslider/>
        <CardSec/>   */}

        <Footer/>


        {/* <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><b>Fraud Alert!</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Beware of impostors using our name for scams. We're committed to your safety. Report suspicious activity</p>
          <p>Stay vigilant and thank you for your trust.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
        </div>
    );
} 

export default Home;