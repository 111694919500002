import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar2 from './design_sidebar2';
 
 class Branding extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title>Professional Digital Branding Services | Design By Humans</title>
	<meta name="description" content="Get professional digital branding services that will help you create a unique and effective brand identity. Our team of experts will help you design a logo, color scheme, and aesthetic language that represents your business perfectly." />
	<link rel="canonical" href="https://bicads.com/branding-services" />
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Branding Services' subtitle='Branding Services' BkImg='/assets/images/bicads/Banner/Branding-Services.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> Branding Services</h3>

            <h2 className='px-5 '>We Will Help Your Brand Get The Recognition It Deserves</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" my-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceSecond pt-5'>
                        <h1 className='mainTextH4'>Branding Services In Pune and Mumbai (India)


</h1>

                        <p className=' mainTextP'>Your company deserves to have its image portrayed by industry leaders. We're here to collaborate with you to craft a logo, color palette, and visual style that perfectly embodies your unique identity. But branding is more than just a logo - it's the art of steering public opinion about your brand's essence, its values, and the way it offers its products or services to consumers. We're equipped to help you forge visual narratives that are unambiguous and impactful for your target market.







</p>  
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/Branding-Services.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h1 className='mainTextH1'>How It Works?</h1>

            <h2 className='px-5 '>Stand Out From The Crowd With A Custom Brand</h2>

            <p>Creative branding services are the key to every successful business brand. It is not enough to just have a unique logo; colours must also stand out. Stand out from the crowd and give your company a head start with our creative branding services.</p>

                </Container>
            </div>
            {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                <Row >
                    
                    <Col lg="9">
                     <Row >
                    

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Communicate your message
 </h4>

                        <p className=' mainTextP'>In today's business landscape, the perception of a corporation and its personnel plays a significant role. This is why we place such a high priority on brand creation. Our squad of creative graphic artists specializes in creating impactful logos, informative brochures, and various other promotional resources. But we're not just about visuals! We also offer a plethora of services to bolster your communication methods, infographics included! We're thrilled to help you express your message in the most effective and exciting way possible.</p>  
                        </div>
                                     </Col>
                    

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Build trust and loyalty

 </h4>

                        <p className=' mainTextP'>The core of any thriving transaction rests upon the pillar of trust, an integral part of all relationships. The rapport between businesses and their consumers is solidified through regular, personalized communication. It's true that the ultimate method of delivering value is simply to provide it. Aspects such as quality, cost, and the time and place all contribute to an extrinsic value. Various strategies like upselling and cross-selling can also be employed to encourage customers to maintain their loyalty to a specific brand.
</p>  
                        </div>
                                     </Col>
                   
                      
                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Differentiate your company
 </h4>

                        <p className=' mainTextP'>Let's craft an eye-catching video that will distinguish your business from the crowd and magnetize your target viewers. We are eager to collaborate with you to produce a video that not only resonates with your audience but also fulfills your requirements.</p>  
                        </div>
                                     </Col>
                                     </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar2/>
                            </Col>   
                            </Row>
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}

             <Footer/>
        </div>
    );
} 
}
export default Branding;