import React, { Component } from 'react';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import { NavLink } from 'react-router-dom';

class AboutUs extends Component {

    render(){
        return(
            <div className=" AboutUs   AboutBgRight" style={{ 
                backgroundImage: `url(/assets/images/bicads/Banner/about-us-design.png)` 
              }}> 
                <Container  className=" py-4  ">
                    <div className='text-center'>
                <h1 className='mainTextH1'> Who we are</h1>
                        <h4>Solutions to everything to build and maintain your online presence!</h4>
                        </div>
                     <Row className='pb-4'>
                        <Col lg="5">
                        <Image src="/assets/images/bicads//Banner/About-us-image-with-shadow.png" alt={config.SITE_NAME} fluid  rounded/>
 
                                             </Col>
                       <Col lg="6"  className='py-4    animateBtn'>
                       
                       
<p>  We are a Creative Digital Marketing Agency Backed Up With Highly Experienced Web Developers, Graphics Designers, S.E.O Experts & Google Adwords Professionals, You Can Be Sure To Get Your Online Business Sky Rocketing In Coming Months. 

</p>

<NavLink  to="/about"   activeclassname="active" ><button type="button" class="btn btn-primary btn-md  draw mt-3">Read More</button></NavLink>
                                             </Col>
                    </Row>
                </Container>
            </div>
        );
    }

}

export default AboutUs;