import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar2 from './design_sidebar2';
 
 class PayPerClick extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title> PPC – Pay Per Click Company | Agency</title>
	<meta name="description" content="We are a PPC company that can help you set up campaigns that target the specific interests of your ideal customers via Google AdWords or Facebook Ads." />
	<link rel="canonical" href="https://bicads.com/ppc-pay-per-click-marketing-services" />

   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Pay-Per-Click' subtitle='Pay-Per-Click' BkImg='/assets/images/bicads/Banner/PPC.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> Pay-Per-Click</h3>

            <h2 className='px-5 '>Sky Rocket Your Business With Quality Leads</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" my-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceSecond pt-4'>
                        <h1 className='mainTextH4'>PPC – Pay Per Click Marketing Services In Pune and Mumbai (India)

</h1>

                        <p className=' mainTextP'>Get ready to skyrocket your business's online presence with our top-notch pay per click marketing services, designed to drive targeted traffic and yield fantastic results!</p>  

                        <p className=' mainTextP'>This marketing strategy allows businesses to compensate a publisher each time an individual clicks or views an advertisement on a search result, webpage, social media update, or any other digital platform. Compared to the more enduring method of organically gaining attention, this tactic is far more effective. PPC platforms that focus on driving traffic, which are more intent-based, are often more prevalent than programmatic ones.</p>  

                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/PPC.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>How It Works?</h3>

            <h2 className='px-5 '>Grow your business without the expensive upfront costs


</h2>

            <p>We can help you set up campaigns that target the specific interests of your ideal customers via Google AdWords or Facebook Ads. We can help you optimize your website's content to receive more organic traffic from Google and Facebook by identifying keyword and keyword groupings that your target customers are likely to type into Google or Facebook search boxes. We can also set up PPC campaigns with Google Shopping and Facebook Commerce. By identifying products that your target customers are likely to search for on Google and Facebook, we can help you generate more traffic to your website and increase your website conversion rate. If you have a physical retail store, you may also be able to set up a Google Merchant Center AdWords campaign to generate more offline foot traffic.</p>
 

  
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                <Row >
                    
                    <Col lg="9">
                     <Row >
                       
                      
                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Build a successful PPC campaign
 </h4>

                        <p className=' mainTextP'>We've got the know-how to optimize the impact of Google Ads, Facebook promotions, Twitter adverts, and various pay-per-click (PPC) networks. Armed with our proficiency in conversion enhancement and your financial plan, we will construct the perfect advertising drive.</p>  
                        </div>
                                     </Col>
                   

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Generate leads from social media

 </h4>

                        <p className=' mainTextP'>Harnessing the power of PPC marketing through social media platforms is an exciting route to creating robust lead generation. The affordability, scalability, and measurability of this approach make it a formidable tool in your marketing arsenal. Plus, it's not just about the business side of things; it's also an enjoyable journey! We're here to give a helping hand to small enterprises, guiding them to flourish on social media using paid-per-click advertisements across platforms like Facebook, LinkedIn, Twitter, and Instagram.

</p>  
                        </div>
                                     </Col>
                   

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Reach your target audience, Faster
 </h4>

                        <p className=' mainTextP'>PPC marketing is a powerful tool that can swiftly and effectively connect you with your desired audience, thereby boosting your income. Platforms such as Google Ads, pay-per-click outlets, or programmatic channels provide the opportunity to purchase advertising slots on popular websites like Yahoo! and Bing. Regardless of what your ambitions may be, it's been consistently demonstrated that PPC advertising services can significantly enhance your return on investment.</p>  
                        </div>
                                     </Col>
                  

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Get instant visibility for your business
 </h4>

                        <p className=' mainTextP'>Opting for pay-per-click marketing solutions can be an incredible decision if you're aiming to engage a more extensive audience. This strategy allows you to buy immediate visibility, a stark contrast to the gradual build-up associated with organic methods. This technique will rapidly escalate your company's exposure and enhance its recognition in no time at all!

</p>  
                        </div>
                                     </Col>
                                     </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar2/>
                            </Col>   
                            </Row>
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}


             <Footer/>
        </div>
    );
} 
}
export default PayPerClick;