import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar2 from './design_sidebar2';
 
 class WikipediaPage extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <title>Wikipedia Page Creation Services - Get Your Business Page Today!</title>
	<meta name="description" content="Add or modify content on Wikipedia and get an automatic SEO boost! Our Wikipedia page creation services will help you get the online presence and recognition you need." />
	<link rel="canonical" href="https://bicads.com/wikipedia-page-creation-service" />
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Wikipedia Page Creation' subtitle='Wikipedia Page Creation' BkImg='/assets/images/bicads/Banner/Wikipedia.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

        <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'> Wikipedia Page Creation</h3>

            <h2 className='px-5 '>Get The Extra Edge On Search Engines & Brand Value</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className=" my-4  ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceSecond pt-5'>
                        <h1 className='mainTextH4'>Wikipedia Page Creation Services In Pune and Mumbai (India)
</h1>

                        <p className=' mainTextP'>Imagine the thrill of seeing your brand's name pop up in those coveted top search results, all thanks to the magic of Wikipedia page creation - it's a game changer!</p>  

                        <p className=' mainTextP'>Having a Wikipedia page is a strong affirmation of your brand's digital existence and acknowledgement. With Wikipedia regularly landing in Google's top five positions, your company's page receives an automatic uplift in search engine optimization. This means that your business page will experience an innate SEO enhancement from Google. Wikipedia provides you with the opportunity to generate, enhance or alter content as needed.</p>  

                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Banner/Wikipedia.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>How It Works?</h3>

            <h2 className='px-5 '>Get Your Brand On Wikipedia</h2>

            <p>Our team of content developers, editors, and designers will create a Wikipedia page for your business that is packed with information and is SEO-friendly. Don’t bother figuring out Google’s ranking algorithm guidelines; we’ll take care of it for you.
</p>
 

  
                </Container>
            </div>
          {/* -------------------------------------------------------------------------- */}

<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                <Row >
                    
                    <Col lg="9">
                     <Row >
                    

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Your brand’s online recognition
 </h4>

                        <p className=' mainTextP'>A Wikipedia page has the power to elevate your brand's prominence and acknowledgement, not to mention it can help your content to secure a spot in Google's search engine results pages (SERPs). The additional perk of having a Wikipedia page is the SEO enhancement, thanks to its high ranking position on Google. Securing one of the top five positions on Google can also provide a significant boost to your other web pages, helping them climb up the ranks too. With these advantages, it's clear that a Wikipedia page can be a game-changer for your brand's online presence. Let your brand bask in the limelight and enjoy higher visibility with an enhanced SEO positioning.</p>  
                        </div>
                                     </Col>
                   
                       
                        

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Put your Brand out there

 </h4>

                        <p className=' mainTextP'>Creating a Wikipedia page for your company is a fantastic supplement to your Google advertising scheme. It's a stellar strategy for drawing attention to your brand and broadening its visibility. Don't hesitate, go and get your page today!
</p>  
                        </div>
                                     </Col>
                   

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Build your brand and enjoy a reflex SEO boost
 </h4>

                        <p className=' mainTextP'>Wikipedia, a familiar name in the online world, garners an impressive 460 million visits each month, making it one of the topmost visited websites globally. The smart move here is to take advantage of Wikipedia's ingenious SEO enhancement program. Building a substantial digital footprint is not a walk in the park; it requires persistence, sheer determination, and consistent hard work. So, don't miss out on this fantastic chance. It's high time you jumped on board!</p>  
                        </div>
                        </Col>
                                     </Row>
                    
                    </Col>
                        <Col lg="3">
                        <DesignSidebar2/>
                            </Col>   
                            </Row>
                </Container>
            </div>
            </div>
        {/* -------------------------------------------------------------------------- */}



             <Footer/>
        </div>
    );
} 
}
export default WikipediaPage;