import React, { Component }  from 'react';

class ErrorPage extends Component {

    render(){
     return(
        <div>
            <h1>404</h1>
        </div>
    );
}
}

export default ErrorPage;