import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

       
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Helmet, HelmetProvider } from "react-helmet-async";
import SubHeader from '../../components/layout/subheader';
import config from '../../constant';
import Header from '../../components/layout/header';
import Footer from '../../components/layout/footer';
import DesignSidebar from './design_sidebar';
import { NavLink } from 'react-router-dom';

 class WebsiteDesign extends Component {

    // componentDidMount(){
    //     document.title = "Digital Marketing Services &amp; Website Development | Pune | Mumbai "
    //   }

    render(){

    return(
        <div className='MainServiceDiv'>
 
             <HelmetProvider>
             <meta charSet="utf-8" />
             <title>Website Development Company | Web Design Services</title>
             <meta name="description" content="Need a website development company? We are a web design services company that can help you create a stunning website that will make an impact on your audience." />

             <link rel="canonical" href="https://bicads.com/website-development-services" />
   </HelmetProvider>
   
           <Header/>
            <SubHeader  Title='Website ' subtitle='Design' BkImg='/assets/images/bicads/Website-Banner.jpg'/>
        {/* ------------------------- intro------------------------------------------------- */}

     
        <div className='ServiceHeading  '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>  Website design Company</h3>

            <h2 className='px-5 '>What makes a company great? <br></br> That’s simple. It’s the people who work here.</h2>

                      
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}
       

            <div className='whyConsistent  ServiceBg'>
                <Container className="py-4 ">

                     <Row >
                        <Col lg="6">
                            <div className='serviceSecond'>
                        <h1 className='mainTextH4'> Website Development Company In Pune and Mumbai (India) </h1>

                        <p className=' mainTextP'>With the help of an enthusiastic website development company, your business will be rocketing to unprecedented heights, making waves in your industry like never before! </p>  
                        <p className=' mainTextP'>A professionally crafted and excellently structured website propels your business into the forefront of your intended market. The prominence of your brand is pivotal for your business's triumph. By employing a proficiently designed website, you can create an indelible impact on your audience, thus making a powerful initial impression. A company specializing in website development could be instrumental in achieving this objective.</p>
                        </div>
                                     </Col>
                       <Col lg="6">
                       <Image src="/assets/images/bicads/Website-image.png" alt={config.SITE_NAME} fluid   />
                                             </Col>
                    </Row>
                </Container>
            </div>
            
  {/* -------------------------------------------------------------------------- */}

 <div className='ServiceHeading '>
                <Container className=" py-5   text-center">
                <h3 className='mainTextH1'>  Our Process </h3>

            <h2 className='px-5 '> We Use An Established SDLC Process <br></br>For Our Design And Development Processs


</h2>

            <Row className='goodWebsite'>
                <Col lg='3'>  
                 <Image src="/assets/images/bicads/Icon-5.png" alt={config.SITE_NAME} fluid   />
                 <h5>Understand Requirement & Analyse</h5>
                 </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-6.png" alt={config.SITE_NAME} fluid   />
                    <h5>Design & Architecture</h5>

                    
                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-7.png" alt={config.SITE_NAME} fluid   />
                    <h5>Implementation & Coding</h5>

                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-8.png" alt={config.SITE_NAME} fluid   />
                    <h5>Testing</h5>
                    </Col>
            </Row>
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}

             {/* -------------------------------------------------------------------------- */}
             <div className='ServiceHeading '>
                <Container className=" pb-5   text-center">
                <h3 className='mainTextH1'>  Good Website </h3>

            <h2 className='px-5 '>A Well Designed & Performing Website Should Have</h2>

            <Row className='goodWebsite'>
                <Col lg='3'>  
                 <Image src="/assets/images/bicads/Icon-1.png" alt={config.SITE_NAME} fluid   />
                 <h5>Optimum Loading Speed</h5>
                 </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-2.png" alt={config.SITE_NAME} fluid   />
                    <h5>SEO Friendly Design</h5>

                    
                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-3.png" alt={config.SITE_NAME} fluid   />
                    <h5>Fully Mobile Responsive</h5>

                    </Col>
                <Col lg='3'>
                    <Image src="/assets/images/bicads/Icon-4.png" alt={config.SITE_NAME} fluid   />
                    <h5>Right Content Placement</h5>
                    </Col>
            </Row>
                </Container>
            </div>
        {/* -------------------------------------------------------------------------- */}

       
       
<div className='serviceSection'>
        <div className='ServiceBg'>
                <Container className="   ">

                     <Row >
                    
                        <Col lg="9">
                       
                      
                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Optimum Loading Speed
 </h4>

                        <p className=' mainTextP'>Websites that take forever to load are a recipe for disaster. Even though your website may be aesthetically pleasing, it won't matter if it's not user-friendly. A sluggish webpage is detrimental to your venture. Hence, it becomes crucial for you to understand how to delight your users with swift loading times. This strategy doesn't just enhance the user's journey, but it also propels your search engine optimization (SEO).</p>  
                        </div>
                                     </Col>
                

                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>SEO Friendly Design

 </h4>

                        <p className=' mainTextP'>It's not enough to have a visually appealing website; it's crucial that it operates at lightning speed as well. The optimal approach to achieve this is by engaging the services of a professional web design firm. These experts are versed in the correct strategies to enhance your website's traffic and elevate its position on search engine results, leading to an increase in potential clients and revenue.

</p>  
                        </div>
                        </Col>
                  
                       
                       

                                             <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Fully Mobile Responsive
 </h4>

                        <p className=' mainTextP'>The usage of mobile devices has now eclipsed that of desktop and laptop computers. Owing to their soaring popularity, it's become imperative for websites to be optimized for mobile viewing. It's crucial that users can easily locate the information they need and view it in a format that's legible on their mobile devices.</p>  
                        </div>
                                     </Col>
                   
                       
                       
                     <Col lg="12">
                            <div className=' pt-5  '>
                        <h4 className='mainTextH4'>Right Content Placement
 </h4>

                        <p className=' mainTextP'>Web content serves as more than a mere tool for advertisement and trading goods; it's the embodiment of a corporate brand. Misplacement of such content could lead to financial losses for the business. Hence, identifying the appropriate location on your website for certain content is crucial in attracting and converting prospective customers.

</p>  
                        </div>
                                     </Col>

                                     </Col>
                        <Col lg="3">
                        <DesignSidebar/>
                            </Col>     
                    </Row>
                </Container>
            </div>
            </div>


 
        {/* -------------------------------------------------------------------------- */}

             <Footer/>
        </div>
    );
} 
}
export default WebsiteDesign;